:root {
  --smallerSpace: 60px;
}
@media (max-width: 1197px) {
  :root {
    --smallerSpace: 40px;
  }
}
:root {
  --deskWidth: 1406px;
  --deskSpace: 80px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1197px) {
  :root {
    --deskSpace: 50px;
  }
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 36px;
  }
}
:root {
  --spacePart: 20px;
}
@media (max-width: 1197px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 40px;
}
@media (max-width: 1197px) {
  :root {
    --spaceUnit: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 60px;
}
@media (max-width: 1197px) {
  :root {
    --spaceTotal: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #e5005a;
  font-size: 19px;
  line-height: 1.47368421;
  letter-spacing: 0.025em;
  font-weight: 700;
  cursor: pointer;
  color: #fff;
  padding: 11px 40px;
  -webkit-appearance: none;
  border-radius: 10000000px;
  box-sizing: border-box;
  text-align: left;
  text-align: center;
  text-decoration: none;
  background-image: none !important;
}
.button:hover,
.button:focus {
  background-color: #F9B232;
}
@media (max-width: 1197px) {
  .button {
    font-size: 14px;
    line-height: 1.14285714;
    padding: 9px 20px;
  }
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #e5005a;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 600;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 25px;
  font-family: 'Inter', sans-serif;
  line-height: 1.8;
}
.unit caption {
  display: none;
}
.flag {
  background: #e5005a;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #C0C0C0;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.section--largeMood #expo {
  margin-top: 80px;
  margin-bottom: 0;
}
.section--smallMood #expo {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 1197px) {
  .section--largeMood #expo {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .section--smallMood #expo {
    width: 100vw;
    margin-left: calc(var(--deskSpace) * -1);
  }
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 47.65291607%;
  position: relative;
}
.section--largeMood #slides {
  padding-bottom: 47.65625%;
}
.section--smallMood #slides {
  padding-bottom: 760px;
}
@media (max-width: 1197px) {
  .section--smallMood #slides {
    padding-bottom: 600px;
  }
}
@media (max-width: 767px) {
  .section--smallMood #slides {
    padding-bottom: 460px;
  }
}
@media (max-width: 495px) {
  .section--largeMood #slides {
    padding-bottom: 236px;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
.section--smallMood #expo div.link {
  display: block;
}
#expo a.link {
  position: absolute;
  z-index: 2;
  width: 98px;
  height: 98px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 22px 40px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
@media (max-width: 1197px) {
  #expo a.link {
    width: 70px;
    height: 70px;
  }
}
@media (max-width: 767px) {
  #expo a.link {
    display: none;
  }
}
#expo a.link.prev {
  left: -98px;
  bottom: 98px;
  background-color: #e5005a;
  background-image: url(/images/chevron-left-white.svg);
}
#expo a.link.prev:hover {
  background-color: #F9B232;
}
@media (max-width: 1197px) {
  #expo a.link.prev {
    left: -70px;
    bottom: 70px;
  }
}
#expo a.link.next {
  left: 0;
  bottom: 0;
  background-color: #F9B232;
  background-image: url(/images/chevron-right-white.svg);
}
#expo a.link.next:hover {
  background-color: #e5005a;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  right: 100%;
  bottom: 39px;
  z-index: 2;
  display: flex;
  justify-content: center;
  margin-right: 44px;
}
@media (max-width: 1197px) {
  .cb-index-all {
    bottom: 30px;
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .cb-index-all {
    bottom: initial;
    top: 100%;
    right: var(--deskSpace);
    margin-right: 0;
    margin-top: 24px;
  }
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 15px;
  height: 15px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #E3E3E3;
  border-radius: 10000px;
  transition: all 0.4s;
}
.cb-index-all a:hover {
  background-color: #A0A0A0;
}
@media (max-width: 1197px) {
  .cb-index-all a {
    width: 10px;
    height: 10px;
  }
}
.cb-index-all td.this a {
  background-color: #F9B232 !important;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  display: none;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(229, 0, 90, 0.86);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  line-height: 1.42857143;
  color: #212526;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #e5005a;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #e5005a;
  font-size: 16px;
  font-weight: 600;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: #e5005a;
  font-size: 19px;
  line-height: 1.47368421;
  letter-spacing: 0.025em;
  font-weight: 700;
  cursor: pointer;
  color: #fff;
  padding: 11px 40px;
  -webkit-appearance: none;
  border-radius: 10000000px;
  box-sizing: border-box;
  text-align: left;
  text-align: center;
  text-decoration: none;
  background-image: none !important;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  background-color: #F9B232;
}
@media (max-width: 1197px) {
  #disp .foot input,
  #disp .foot a {
    font-size: 14px;
    line-height: 1.14285714;
    padding: 9px 20px;
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1197px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Inter', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.unit.form .foot {
  display: flex;
  justify-content: center;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form .part.must {
  font-size: 15px;
  line-height: 1.66666667;
  color: #6F6F6F;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #f7b3ce;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
  font-size: 15px;
  line-height: 1.66666667;
  color: #6F6F6F;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 24px;
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  min-height: 64px;
  padding: 5px 20px;
  -webkit-appearance: none;
  border: 1px solid #C0C0C0;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.02em;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  border-color: #e5005a;
  box-shadow: 0 0 0 1px #e5005a;
}
.unit.form input.text:focus,
.unit.form textarea:focus {
  border-color: #F9B232;
  box-shadow: 0 0 0 1px #F9B232;
}
@media (max-width: 767px) {
  .unit.form input.text,
  .unit.form textarea {
    min-height: 50px;
    font-size: 16px;
    line-height: 1.5;
    padding: 5px 14px;
  }
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 9.5px;
    padding-bottom: 9.5px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
  font-size: 18px;
  line-height: 1.33333333;
}
@media (max-width: 767px) {
  .unit.form div.tick label {
    font-size: 16px;
    line-height: 1.5;
  }
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  margin-right: 6px;
  height: 24px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 9.5px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  line-height: 1.8;
  background: transparent;
  height: 64px;
  border: 1px solid #212526;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #f7b3ce;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 64px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  background-color: #e5005a;
  font-size: 19px;
  line-height: 1.47368421;
  letter-spacing: 0.025em;
  font-weight: 700;
  cursor: pointer;
  color: #fff;
  padding: 11px 40px;
  -webkit-appearance: none;
  border-radius: 10000000px;
  box-sizing: border-box;
  text-align: left;
  text-align: center;
  text-decoration: none;
  background-image: none !important;
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  background-color: #F9B232;
}
@media (max-width: 1197px) {
  .unit.form input.submit {
    font-size: 14px;
    line-height: 1.14285714;
    padding: 9px 20px;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.pile .calendar {
  left: initial !important;
  right: 0 !important;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar th a,
.calendar td a {
  text-decoration: none;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #212526;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
  text-align: center;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #e5005a;
  box-sizing: border-box;
  padding: 20px;
  background-color: #f7b3ce;
  color: #e5005a;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #e5005a;
  font-size: 19px;
  line-height: 1.47368421;
  letter-spacing: 0.025em;
  font-weight: 700;
  cursor: pointer;
  color: #fff;
  padding: 11px 40px;
  -webkit-appearance: none;
  border-radius: 10000000px;
  box-sizing: border-box;
  text-align: left;
  text-align: center;
  text-decoration: none;
  background-image: none !important;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  background-color: #F9B232;
}
@media (max-width: 1197px) {
  .two-step-verification-container a {
    font-size: 14px;
    line-height: 1.14285714;
    padding: 9px 20px;
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 30px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0 !important;
  white-space: nowrap;
}
.table th.exit {
  padding-right: 0 !important;
}
@media (max-width: 1197px) {
  .table th {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .table th {
    padding: 10px;
  }
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 600;
}
.table td {
  padding: 30px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0 !important;
  white-space: nowrap;
}
.table td.exit {
  padding-right: 0 !important;
}
@media (max-width: 1197px) {
  .table td {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .table td {
    padding: 10px;
  }
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 600;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 3.25945241vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  height: 82px;
  margin-top: 70px;
  margin-bottom: 40px;
}
@media (max-width: 1449px) {
  #home {
    height: 62px;
    margin-top: 80px;
  }
}
@media (max-width: 1197px) {
  #home {
    height: 38px;
    margin-top: 25px;
    margin-bottom: 44px;
  }
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: var(--spaceTotal);
}
.layout1 #head,
.layout2 #head {
  margin-top: 40px;
}
@media (max-width: 1197px) {
  #head {
    margin-top: 20px;
  }
  .layout1 #head,
  .layout2 #head {
    margin-top: 20px;
  }
  .layout6 #head {
    margin-top: calc(var(--spaceTotal) * 2);
  }
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  line-height: 1.8;
  color: #212526;
  overflow: hidden;
  hyphens: none;
  position: relative;
  box-sizing: border-box;
  padding-top: 154px;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 1449px) {
  .wrapper {
    padding-top: 142px;
  }
}
@media (max-width: 1197px) {
  .wrapper {
    padding-top: 122px;
  }
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.layout1 .section--title .desk,
.layout4 .section--title .desk,
.layout6 .section--title .desk,
.section--one .desk,
.section--four .desk,
.section--newsletter .desk {
  width: 1038px;
}
@media (max-width: 1197px) {
  .section--multimood .desk {
    max-width: 100%;
  }
}
.section {
  float: left;
  width: 100%;
  position: relative;
  z-index: 2;
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: #fff;
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
}
.section--header.cb-scroll-triggered--active,
body.cb-toggle-target-active .section--header {
  border-color: #C0C0C0;
}
.section--lighterred {
  background-color: #FFF2EB;
  margin-top: var(--spaceTotal);
  margin-bottom: calc(var(--spaceTotal) * -1);
  padding-top: var(--spaceTotal);
  padding-bottom: var(--spaceTotal);
  z-index: 3;
  display: none;
}
#edit.layout1 .section--lighterred.section--area2,
#view.layout1:not(.area2--empty) .section--lighterred.section--area2,
#edit.layout2 .section--lighterred.section--area2,
#view.layout2:not(.area2--empty) .section--lighterred.section--area2,
#edit.layout6 .section--lighterred.section--area2,
#view.layout6:not(.area2--empty) .section--lighterred.section--area2 {
  display: block;
}
.section--lightblue {
  background-color: #EBF4FF;
  margin-top: var(--spaceTotal);
  margin-bottom: calc(var(--spaceTotal) * -1);
  padding-top: var(--spaceTotal);
  padding-bottom: var(--spaceTotal);
  z-index: 3;
  display: none;
}
#edit.layout3 .section--lightblue.section--area4,
#view.layout3:not(.area4--empty) .section--lightblue.section--area4,
#edit.layout6 .section--lightblue.section--area4,
#view.layout6:not(.area4--empty) .section--lightblue.section--area4 {
  display: block;
}
.section--footer {
  border-top: 1px solid #e5005a;
  margin-top: var(--spaceTotal);
}
.navigation {
  float: right;
  width: calc(100% - 400px);
  margin-top: 74px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 1449px) {
  .navigation {
    width: calc(100% - 280px);
  }
}
@media (max-width: 1197px) {
  .navigation {
    margin-top: 25px;
    align-items: center;
    justify-content: flex-start;
    width: auto;
  }
}
.navigation .meta.find {
  float: left;
  margin-top: 20px;
  font-size: 20px;
  line-height: 1.2;
  text-decoration: none;
  padding-right: 26px;
  color: #212526;
  background-size: 16px 18px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-search-black.svg);
}
.navigation .meta.find:hover,
.navigation .meta.find:focus {
  background-image: url(/images/icon-search-red.svg);
}
@media (max-width: 1449px) {
  .navigation .meta.find {
    font-size: 17px;
    line-height: 1.41176471;
  }
}
@media (max-width: 1197px) {
  .navigation .meta.find {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    color: transparent;
    padding: 0;
    width: 16px;
    height: 18px;
    margin: 0;
  }
}
.content {
  float: left;
  width: 100%;
}
.section--header .content {
  position: relative;
}
@media (max-width: 1197px) {
  .section--header .content {
    padding-top: 14px;
  }
}
.section--smallMood .content {
  margin-top: 40px;
  margin-bottom: var(--spaceTotal);
  display: none;
}
.layout2 .section--smallMood .content {
  display: block;
}
.subcontent {
  float: left;
  width: 100%;
}
.colorStripes {
  display: flex;
}
.colorStripes--header {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  width: calc(100% - 400px);
}
@media (max-width: 1449px) {
  .colorStripes--header {
    width: calc(100% - 280px);
  }
}
@media (max-width: 1197px) {
  .colorStripes--header {
    width: 100vw;
    right: calc(var(--deskSpace) * -1);
  }
}
.colorStripe {
  float: left;
  height: 30px;
  transition: all 0.4s;
}
.cb-scroll-triggered--active .colorStripe {
  height: 12px;
}
@media (max-width: 1197px) {
  .colorStripe {
    height: 14px;
  }
}
.colorStripe--1 {
  background-color: #fcd48c;
}
.colorStripe--2 {
  background-color: #f173a4;
}
.colorStripe--3 {
  background-color: #CDE9E8;
}
.colorStripe--4 {
  background-color: #BDC3E0;
}
.colorStripe--5 {
  background-color: #D3E0BD;
}
.colorStripe--6 {
  background-color: #E2C9ED;
}
.colorStripes--header .colorStripe--1 {
  width: 40%;
}
.colorStripes--header .colorStripe--2 {
  width: 18%;
}
.colorStripes--header .colorStripe--3 {
  width: 13%;
}
.colorStripes--header .colorStripe--4 {
  width: 5%;
}
.colorStripes--header .colorStripe--5 {
  width: 13%;
}
.colorStripes--header .colorStripe--6 {
  width: 11%;
}
.colorStripes--footer .colorStripe--1 {
  width: 40%;
}
.colorStripes--footer .colorStripe--2 {
  width: 22%;
}
.colorStripes--footer .colorStripe--3 {
  width: 11%;
}
.colorStripes--footer .colorStripe--4 {
  width: 15%;
}
.colorStripes--footer .colorStripe--5 {
  width: 6%;
}
.colorStripes--footer .colorStripe--6 {
  width: 6%;
}
.backlink {
  float: left;
  margin-top: 88px;
  width: 78px;
  height: 30px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/backlink-black.svg);
  cursor: pointer;
  display: none;
}
.backlink:hover,
.backlink:focus {
  background-image: url(/images/backlink-red.svg);
}
.layout1 .backlink,
.layout2 .backlink {
  display: block;
}
@media (max-width: 1197px) {
  .backlink {
    width: 52px;
    height: 20px;
    margin-top: 0;
  }
}
.newsletter {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 3);
  margin-bottom: var(--spaceTotal);
}
.newsletter__part {
  float: left;
  width: 100%;
  margin-top: 70px;
}
.newsletter__part:first-child {
  margin-top: 0 !important;
}
@media (max-width: 1197px) {
  .newsletter__part {
    margin-top: 30px;
  }
}
.newsletter__part--title {
  font-size: 39px;
  line-height: 1.17948718;
  letter-spacing: 0.01em;
  font-weight: 500;
}
.footcontent {
  float: left;
  width: 100%;
  margin: 100px 0;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 1.8;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #e5005a;
}
.footcontent a {
  color: #e5005a;
  text-decoration: none;
}
.footcontent a:hover,
.footcontent a:focus {
  color: #212526;
}
@media (max-width: 1449px) {
  .footcontent {
    font-size: 17px;
    line-height: 1.76470588;
  }
}
@media (max-width: 1197px) {
  .footcontent {
    flex-direction: column;
    align-items: flex-start;
    margin-top: calc(var(--spaceTotal) * 2);
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .footcontent {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
.footunit {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 1197px) {
  .footunit {
    margin-top: calc(var(--spaceTotal) * 2);
  }
  .footunit:first-child {
    margin-top: 0 !important;
  }
}
@media (max-width: 767px) {
  .footunit {
    width: 100%;
  }
}
.foothome {
  float: left;
  height: 82px;
}
@media (max-width: 1449px) {
  .foothome {
    height: 62px;
  }
}
.vcard {
  float: left;
  margin-top: 40px;
}
@media (max-width: 1197px) {
  .vcard {
    margin-top: calc(var(--spaceTotal) * 2);
  }
}
#social {
  float: left;
}
#social .meta {
  float: left;
  width: 40px;
  height: 40px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 20px 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-instagram-red-2022-09-15.svg);
  margin-right: 15px;
  box-sizing: border-box;
  border: 1px solid #e5005a;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta:hover,
#social .meta:focus {
  background-color: #e5005a;
  background-image: url(/images/social-instagram-white.svg);
}
#social .meta.service_linkedin {
  background-image: url(/images/social-linkedin-red-2022-09-15.svg);
}
#social .meta.service_linkedin:hover,
#social .meta.service_linkedin:focus {
  background-image: url(/images/social-linkedin-white.svg);
}
#social .meta.service_facebook {
  background-image: url(/images/social-facebook-red-2022-09-15.svg);
}
#social .meta.service_facebook:hover,
#social .meta.service_facebook:focus {
  background-image: url(/images/social-facebook-white.svg);
}
#social .meta.service_youtube {
  background-image: url(/images/social-youtube-red-2022-09-15.svg);
}
#social .meta.service_youtube:hover,
#social .meta.service_youtube:focus {
  background-image: url(/images/social-youtube-white.svg);
}
#legal {
  position: absolute;
  right: 0;
  bottom: 50px;
  z-index: 2;
}
#legal .meta {
  float: left;
  font-size: 13px;
  line-height: 1.53846154;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #e5005a;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 26px;
}
#legal .meta:hover,
#legal .meta:focus {
  color: #212526;
}
#legal .meta:last-child {
  margin-right: 0 !important;
}
@media (max-width: 1197px) {
  #legal {
    position: static;
    float: left;
    margin-top: calc(var(--spaceTotal) * 2);
    margin-bottom: calc(var(--spaceTotal) * 2);
  }
}
.quicklinks {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .quicklinks {
    width: 100%;
  }
}
.quicklinks__part {
  float: left;
  margin-top: 40px;
}
.quicklinks__part:first-child {
  margin-top: 0 !important;
}
@media (max-width: 1197px) {
  .quicklinks__part {
    margin-top: 20px;
  }
}
.quicklinks__part--title {
  font-size: 19px;
  line-height: 1.36842105;
  font-weight: 700;
  letter-spacing: 0.03em;
}
@media (max-width: 767px) {
  .quicklinks__part--title {
    font-size: 14px;
    line-height: 1.85714286;
  }
}
.quicklinks__part--content {
  display: flex;
}
@media (max-width: 767px) {
  .quicklinks__part--content {
    flex-direction: column;
    align-items: flex-start;
  }
}
.quicklinks .dock {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 60px;
}
.quicklinks .dock:first-child {
  margin-left: 0 !important;
  margin-top: 0 !important;
}
.quicklinks .dock .meta {
  font-size: 17px;
  line-height: 1.29411765;
  font-weight: 400;
  margin-top: 20px;
  white-space: nowrap;
}
.quicklinks .dock .meta:first-child {
  margin-top: 0 !important;
}
@media (max-width: 1449px) {
  .quicklinks .dock {
    margin-left: 50px;
  }
  .quicklinks .dock .meta {
    font-size: 15px;
    line-height: 1.46666667;
  }
}
@media (max-width: 1197px) {
  .quicklinks .dock {
    margin-left: 80px;
  }
}
@media (max-width: 767px) {
  .quicklinks .dock {
    margin-left: 0;
    margin-top: 5px;
  }
  .quicklinks .dock .meta {
    font-size: 14px;
    line-height: 1.85714286;
    margin-top: 5px;
  }
}
.instrument {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 76px;
}
@media (max-width: 1197px) {
  .instrument {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .instrument {
    margin-top: 74px;
  }
}
.instrumentIcon {
  float: left;
  max-width: 110px;
  max-height: 110px;
  margin-right: 24px;
}
@media (max-width: 1197px) {
  .instrumentIcon {
    max-width: 80px;
    max-height: 80px;
  }
}
.instrumentIcon__image {
  float: left;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
a {
  color: inherit;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 1px;
}
a:hover,
a:focus {
  color: #e5005a;
}
h1 {
  font-size: 60px;
  line-height: 1.25;
  font-weight: 600;
}
h1 strong {
  font-weight: 700;
}
.layout3 h1,
.layout4 h1,
.layout5 h1 {
  font-size: 75px;
  line-height: 1.13333333;
  font-weight: 500;
}
h2 {
  font-size: 65px;
  line-height: 1.07692308;
  font-weight: 400;
}
h2 strong {
  font-weight: 500;
}
h3 {
  font-size: 50px;
  line-height: 1.2;
  font-weight: 300;
}
h3 strong {
  font-weight: 500;
}
h4 {
  font-size: 38px;
  line-height: 1.26315789;
  font-weight: 500;
}
h4 strong {
  font-weight: 700;
}
h5 {
  font-size: 35px;
  line-height: 1.28571429;
  font-weight: 700;
}
h6 {
  font-size: 25px;
  line-height: 1.2;
  letter-spacing: 0.02em;
  font-weight: 700;
}
.loud {
  font-size: 15px;
  line-height: 1.73333333;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}
.skew {
  font-size: 60px;
  line-height: 1.33333333;
  letter-spacing: 0.01em;
  max-width: 1200px;
}
.skew:before {
  content: '«';
}
.skew:after {
  content: '»';
}
.pale {
  font-size: 15px;
  line-height: 1.73333333;
}
.pale a {
  text-decoration: none;
}
.area--two p.norm,
.area--three p.norm {
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0.02em;
}
.area--four p.norm,
.area--six p.norm {
  font-size: 17px;
  line-height: 1.52941176;
  font-weight: 500;
  letter-spacing: 0.02em;
}
.area--five p.norm,
.unit.fold p.norm {
  font-size: 22px;
  line-height: 1.54545455;
}
.cbdModule--pageTitleOverline {
  margin-top: 40px;
}
.section--smallMood .cbdModule--pageTitleOverline {
  margin-top: 0;
}
.cbdModule--largeMoodStoerer {
  width: 554px;
  position: absolute;
  right: calc((100% - var(--deskWidth)) / 2);
  top: 40px;
  z-index: 2;
  background-color: #E2C9ED;
  box-sizing: border-box;
  padding: 72px 44px;
  font-size: 28px;
  line-height: 1.42857143;
  letter-spacing: 0.02em;
  font-weight: 700;
}
.cbdModule--largeMoodStoerer:before {
  content: 'Info';
  position: absolute;
  left: 44px;
  top: 32px;
  font-size: 20px;
  line-height: 2;
  font-weight: 600;
  padding-left: 30px;
  background-size: 22px 22px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-info-black.svg);
}
@media (max-width: 1566px) {
  .cbdModule--largeMoodStoerer {
    right: var(--deskSpace);
  }
}
@media (max-width: 1197px) {
  .cbdModule--largeMoodStoerer {
    width: 360px;
    top: 0;
    right: 20px;
    font-size: 18px;
    line-height: 1.38888889;
    padding: 40px 20px;
  }
  .cbdModule--largeMoodStoerer:before {
    left: 20px;
    top: 14px;
    font-size: 14px;
    line-height: 1.71428571;
    background-size: 16px 16px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .cbdModule--largeMoodStoerer {
    position: relative;
    float: right;
    margin: 0 16px -40px 16px;
    right: 0;
    max-width: calc(100% - 32px);
  }
}
.area--one {
  display: flex;
  flex-wrap: wrap;
}
.area--two {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.area--three {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.layout3 .area--three.area5 {
  margin-top: calc(var(--spaceTotal) * 2);
}
.area--four {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: calc(var(--spaceTotal) - (var(--smallerSpace) / 2));
  margin-bottom: calc(var(--spaceTotal) - (var(--smallerSpace) / 2));
}
.area--six {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.layout6 .area--six.area8 {
  margin-top: calc(var(--spaceTotal) * 2);
}
.area--one .pure.wide .pict.tall {
  box-sizing: border-box;
}
.area--one .pure.wide div.link {
  width: auto !important;
  min-width: 60px;
}
.area--one .pure.wide div.link:nth-child(2n) a.open {
  background-color: #F9B232;
}
.area--one .pure.wide div.link:nth-child(2n) a.open:hover,
.area--one .pure.wide div.link:nth-child(2n) a.open:focus {
  background-color: #e5005a;
}
.area--one .seam {
  width: 100vw !important;
  margin-left: -50vw !important;
  margin-right: 0 !important;
  position: relative;
  left: 50%;
}
.area--one .flat div.link {
  display: flex;
  justify-content: center;
}
.area--one .flat.wide {
  margin-bottom: calc(((var(--spaceTotal) + var(--spacePart)) - var(--smallerSpace)) * -1);
}
.area--one .flat.wide:last-child {
  margin-bottom: var(--spaceUnit) !important;
}
.area--one .flat.slim {
  margin-top: calc(((var(--spaceTotal) + var(--spacePart)) - var(--smallerSpace)) * -1);
}
.area--one .flat.slim:first-child {
  margin-top: var(--spaceUnit) !important;
}
.area--two .seam {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
#view .area--two .seam {
  padding-bottom: 38px;
  background-size: 46px 16px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-black.svg);
}
#view .area--two .seam div.link {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
}
#view .area--two .seam div.link a.open {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: none !important;
  padding: 0 !important;
}
#view .area--two .seam:hover {
  background-image: url(/images/arrow-right-red.svg);
}
#view .area--two .seam div.pict .cb-image-figure.svg .cb-image-container {
  overflow: visible;
}
#view .area--two .seam div.pict .cb-image-container {
  overflow: hidden;
}
#view .area--two .seam div.pict .cb-image-container img {
  transition: all 0.4s;
}
#view .area--two .seam:hover div.pict .cb-image-container img {
  transform: scale(1.1);
}
.area--two .seam .body {
  margin-top: -6px;
  margin-bottom: -6px;
}
.area--two .seam .part {
  margin-top: 6px;
  margin-bottom: 6px;
}
.area--two .seam .pict:first-child {
  margin-bottom: 18px;
}
.area--two .flat div.link {
  display: flex;
  justify-content: center;
}
.area--two .flat.wide {
  margin-bottom: calc(((var(--spaceTotal) + var(--spacePart)) - var(--smallerSpace)) * -1);
}
.area--two .flat.wide:last-child {
  margin-bottom: var(--spaceUnit) !important;
}
.area--two .flat.slim {
  margin-top: calc(((var(--spaceTotal) + var(--spacePart)) - var(--smallerSpace)) * -1);
}
.area--two .flat.slim:first-child {
  margin-top: var(--spaceUnit) !important;
}
.area--three .pure {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.area--three .pure.unit--hasFilter {
  margin-top: 0;
  margin-bottom: 0;
}
.area--three .pure .body {
  margin-top: -10px;
  margin-bottom: -10px;
}
.area--three .pure .part {
  margin-top: 10px;
  margin-bottom: 10px;
}
.area--three .pure.slim {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.area--three .pure.slim .body {
  margin-top: -6px;
  margin-bottom: -6px;
}
.area--three .pure.slim .part {
  margin-top: 6px;
  margin-bottom: 6px;
}
.area--three .pure.slim .pict:first-child {
  margin-bottom: 18px;
}
.area--three .seam {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
#view .area--three .seam {
  padding-bottom: 38px;
  background-size: 46px 16px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-black.svg);
}
#view .area--three .seam div.link {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
}
#view .area--three .seam div.link a.open {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: none !important;
  padding: 0 !important;
}
#view .area--three .seam:hover {
  background-image: url(/images/arrow-right-red.svg);
}
.area--three .seam .body {
  margin-top: -6px;
  margin-bottom: -6px;
}
.area--three .seam .part {
  margin-top: 6px;
  margin-bottom: 6px;
}
.area--three .seam .pict:first-child {
  margin-bottom: 18px;
}
.area--three .flat {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
#view .area--three .flat {
  padding-bottom: 38px;
  background-size: 46px 16px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-black.svg);
}
#view .area--three .flat div.link {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
}
#view .area--three .flat div.link a.open {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: none !important;
  padding: 0 !important;
}
#view .area--three .flat:hover {
  background-image: url(/images/arrow-right-red.svg);
}
#view .area--three .flat div.pict .cb-image-figure.svg .cb-image-container {
  overflow: visible;
}
#view .area--three .flat div.pict .cb-image-container {
  overflow: hidden;
}
#view .area--three .flat div.pict .cb-image-container img {
  transition: all 0.4s;
}
#view .area--three .flat:hover div.pict .cb-image-container img {
  transform: scale(1.1);
}
.area--three .flat .body {
  margin-top: -6px;
  margin-bottom: -6px;
}
.area--three .flat .part {
  margin-top: 6px;
  margin-bottom: 6px;
}
.area--three .flat .pict:first-child {
  margin-bottom: 18px;
}
.area--four .unit {
  margin-top: calc((var(--smallerSpace) / 2) - var(--spacePart));
  margin-bottom: calc((var(--smallerSpace) / 2) - var(--spacePart));
}
.area--six .pure .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.area--six .pure div.link {
  width: auto !important;
  min-width: 100px;
}
.area--six .pure div.link + div.link {
  margin-left: 30px;
}
.area--six .pure div.link:nth-child(2n) a.open {
  background-color: #F9B232;
}
.area--six .pure div.link:nth-child(2n) a.open:hover,
.area--six .pure div.link:nth-child(2n) a.open:focus {
  background-color: #e5005a;
}
.area--newsletter .unit {
  margin-top: 0;
  margin-bottom: 0;
  --spacePart: 10px;
}
.area--newsletter .unit .body {
  margin-top: calc(var(--spacePart) * -1);
}
.area--newsletter .unit .foot {
  margin-bottom: calc(var(--spacePart) * -1);
}
.area--newsletter .unit.form .part.must {
  display: none;
}
.area--newsletter .unit.form label.name {
  font-size: 20px;
  line-height: 1.5;
  color: #e5005a;
}
@media (max-width: 1197px) {
  .area--newsletter .unit.form label.name {
    font-size: 16px;
    line-height: 1.625;
  }
}
.area--newsletter .unit.form input.text {
  border-color: #e5005a;
  border-radius: 10000px;
}
.area--newsletter .unit.form input.text:focus {
  border-color: #e5005a;
  box-shadow: 0 0 0 1px #e5005a;
}
.area--newsletter .unit.form .recaptcha-info {
  text-align: right;
}
.area--newsletter .unit.form .foot {
  justify-content: flex-end;
}
.unit.fold {
  border-top: 1px solid #C0C0C0;
  border-bottom: 1px solid #C0C0C0;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.unit.fold + .unit.fold {
  margin-top: calc((var(--spaceTotal) + 1px) * -1);
}
.unit.fold div.less .part {
  margin-top: 0;
  margin-bottom: 0;
}
.unit.fold div.more {
  padding-bottom: 40px;
  box-sizing: border-box;
  padding-right: 150px;
}
.unit.fold .fold-toggle {
  display: block;
  text-decoration: none;
  padding: 30px 94px 30px 0;
  color: #212526;
  position: relative;
}
.unit.fold .fold-toggle:after {
  content: '';
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border: 1px solid #C0C0C0;
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/fold-plus.svg);
  border-radius: 10000px;
  box-sizing: border-box;
}
.unit.fold .fold-toggle.fold-toggle--open:after {
  background-image: url(/images/fold-minus.svg);
}
.unit.fold .foot {
  display: none;
}
@media (max-width: 1197px) {
  .unit.fold .fold-toggle {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 80px;
  }
  .unit.fold .fold-toggle:after {
    width: 36px;
    height: 36px;
    background-size: 20px 20px;
  }
}
@media (max-width: 767px) {
  .unit.fold .fold-toggle {
    padding-right: 56px;
  }
  .unit.fold .fold-toggle:after {
    right: 0;
  }
}
div.link,
div.load {
  font-size: 22px;
  line-height: 1.63636364;
}
div.link.tall + div.link.tall,
div.load.tall + div.link.tall,
div.link.tall + div.load.tall,
div.load.tall + div.load.tall {
  border-top: 1px solid #C0C5C7;
  border-bottom: 1px solid #C0C5C7;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: calc(24px - var(--spacePart));
  margin-bottom: calc((24px + 1px - var(--spacePart)) * -1);
}
div.link.tall + div.link.tall:last-child,
div.load.tall + div.link.tall:last-child,
div.link.tall + div.load.tall:last-child,
div.load.tall + div.load.tall:last-child {
  margin-bottom: var(--spacePart) !important;
}
div.link a.open,
div.load a.open,
div.link a.load,
div.load a.load {
  display: inline-block;
  text-decoration: none;
  color: #212526;
  background-repeat: no-repeat;
}
div.link a.open:hover,
div.load a.open:hover,
div.link a.load:hover,
div.load a.load:hover,
div.link a.open:focus,
div.load a.open:focus,
div.link a.load:focus,
div.load a.load:focus {
  color: #e5005a;
}
div.link a.open {
  padding-right: 36px;
  background-size: 24px 13px;
  background-position: 100% 12px;
  background-image: url(/images/icon-link-intern-red.svg);
}
div.link.site a.open {
  padding-right: 40px;
  background-size: 26px 10px;
  background-position: 100% 13px;
  background-image: url(/images/icon-link-extern-red.svg);
}
div.load a.load {
  padding-right: 26px;
  background-size: 12px 26px;
  background-position: 100% 4px;
  background-image: url(/images/icon-load-red.svg);
}
.area--one .pure.wide div.link + div.link,
.area--two div.link + div.link,
.area--four div.link + div.link,
.area--five div.link + div.link,
.area--six .pure div.link + div.link,
.area--one .pure.wide div.link + div.load,
.area--two div.link + div.load,
.area--four div.link + div.load,
.area--five div.link + div.load,
.area--six .pure div.link + div.load {
  margin-top: var(--spacePart);
  padding-top: 0;
  border-top: none;
}
.area--one .pure.wide div.link a.open,
.area--two div.link a.open,
.area--four div.link a.open,
.area--five div.link a.open,
.area--six .pure div.link a.open {
  background-color: #e5005a;
  font-size: 19px;
  line-height: 1.47368421;
  letter-spacing: 0.025em;
  font-weight: 700;
  cursor: pointer;
  color: #fff;
  padding: 11px 40px;
  -webkit-appearance: none;
  border-radius: 10000000px;
  box-sizing: border-box;
  text-align: left;
  text-align: center;
  text-decoration: none;
  background-image: none !important;
}
.area--one .pure.wide div.link a.open:hover,
.area--two div.link a.open:hover,
.area--four div.link a.open:hover,
.area--five div.link a.open:hover,
.area--six .pure div.link a.open:hover,
.area--one .pure.wide div.link a.open:focus,
.area--two div.link a.open:focus,
.area--four div.link a.open:focus,
.area--five div.link a.open:focus,
.area--six .pure div.link a.open:focus {
  background-color: #F9B232;
}
@media (max-width: 1197px) {
  .area--one .pure.wide div.link a.open,
  .area--two div.link a.open,
  .area--four div.link a.open,
  .area--five div.link a.open,
  .area--six .pure div.link a.open {
    font-size: 14px;
    line-height: 1.14285714;
    padding: 9px 20px;
  }
}
.section--lighterred .area--one .pure.wide div.link a.open,
.section--lighterred .area--two div.link a.open,
.section--lighterred .area--four div.link a.open,
.section--lighterred .area--five div.link a.open,
.section--lighterred .area--six .pure div.link a.open {
  background-color: transparent;
  border: 1px solid #212526;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #212526;
  font-weight: 600;
}
.section--lighterred .area--one .pure.wide div.link a.open:hover,
.section--lighterred .area--two div.link a.open:hover,
.section--lighterred .area--four div.link a.open:hover,
.section--lighterred .area--five div.link a.open:hover,
.section--lighterred .area--six .pure div.link a.open:hover,
.section--lighterred .area--one .pure.wide div.link a.open:focus,
.section--lighterred .area--two div.link a.open:focus,
.section--lighterred .area--four div.link a.open:focus,
.section--lighterred .area--five div.link a.open:focus,
.section--lighterred .area--six .pure div.link a.open:focus {
  box-shadow: 0 0 0 1px #212526;
}
.area--four .unit div.link a.open {
  background-color: transparent;
  border: 1px solid #212526;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #212526;
  font-weight: 600;
}
.area--four .unit div.link a.open:hover,
.area--four .unit div.link a.open:focus {
  box-shadow: 0 0 0 1px #212526;
}
.area--five .unit.slim div.link a.open {
  background-color: #F9B232;
}
.area--five .unit.slim div.link a.open:hover,
.area--five .unit.slim div.link a.open:focus {
  background-color: #e5005a;
}
.area--three .pure div.link {
  margin-top: 0;
}
.area--three .pure div.link + div.link,
.area--three .pure div.link + div.load {
  margin-top: var(--spacePart);
  padding-top: 0;
  border-top: none;
}
#view .area--three .pure div.link a.open {
  float: left;
  padding: 10px 0 0 0;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 46px;
  height: 16px;
  background-size: 46px 16px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-black.svg);
}
#view .area--three .pure div.link a.open:hover,
#view .area--three .pure div.link a.open:focus {
  background-image: url(/images/arrow-right-red.svg);
}
.area--six .seam div.link + div.link,
.area--six .seam div.link + div.load {
  margin-top: var(--spacePart);
  padding-top: 0;
  border-top: none;
}
.area--six .seam div.link a.open {
  font-size: 19px;
  line-height: 1.36842105;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding: 0;
  background: none;
  color: #212526 !important;
}
.area--six .seam div.link a.open:hover,
.area--six .seam div.link a.open:focus {
  text-decoration: underline;
  text-decoration-color: #e5005a;
  text-underline-offset: 5px;
}
@media (max-width: 1197px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
  .navi {
    display: none;
  }
}
.togglenavigation {
  position: relative;
  float: left;
  width: 24px;
  height: 16px;
  cursor: pointer;
  margin-left: 18px;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background-color: #212526;
  border-radius: 2px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline {
  background-color: #e5005a;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #212526;
  text-decoration: none;
}
.mobile-navigation div.navi > .item > .menu.path {
  background-color: #F0F0F0;
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  margin-top: 121px;
  margin-bottom: 80px;
}
.mobile-navigation div.sub1 > .item {
  border-top: 1px solid #C0C0C0;
}
.mobile-navigation div.sub1 > .item.exit {
  border-bottom: 1px solid #C0C0C0;
}
.mobile-navigation div.sub1 > .item.item-empty > .menu {
  padding-right: var(--deskSpace);
}
.mobile-navigation div.sub1 > .item > .menu {
  font-size: 15px;
  line-height: 1.6;
  padding: 18px calc(var(--deskSpace) + 34px) 18px var(--deskSpace);
}
.mobile-navigation div.sub2 > .item.exit {
  margin-bottom: 10px;
}
.mobile-navigation div.sub2 > .item > .menu {
  font-size: 13px;
  line-height: 1.23076923;
  padding: 17px var(--deskSpace);
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub2 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: calc(var(--deskSpace) - 10px);
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 44px;
  height: 60px;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/mnavi-plus.svg);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  background-image: url(/images/mnavi-minus.svg);
}
@media (max-width: 1197px) {
  .wrapper {
    font-size: 20px;
    line-height: 1.5;
  }
  h1 {
    font-size: 25px;
    line-height: 1.4;
  }
  .layout3 h1,
  .layout4 h1,
  .layout5 h1 {
    font-size: 30px;
    line-height: 1.33333333;
  }
  h2 {
    font-size: 30px;
    line-height: 1.33333333;
  }
  h3 {
    font-size: 25px;
    line-height: 1.4;
  }
  h4 {
    font-size: 22px;
    line-height: 1.45454545;
  }
  h5 {
    font-size: 22px;
    line-height: 1.18181818;
  }
  h6 {
    font-size: 20px;
    line-height: 1.5;
  }
  .cbdModule--pageTitleOverline h6 {
    font-size: 16px;
    line-height: 1.375;
  }
  .loud {
    font-size: 14px;
    line-height: 1.85714286;
  }
  .skew {
    font-size: 25px;
    line-height: 1.4;
  }
  .pale {
    font-size: 14px;
    line-height: 1.85714286;
  }
  .area--two p.norm,
  .area--three p.norm {
    font-size: 16px;
    line-height: 1.625;
  }
  .area--four p.norm,
  .area--six p.norm {
    font-size: 14px;
    line-height: 1.71428571;
  }
  .area--five p.norm,
  .unit.fold p.norm {
    font-size: 16px;
    line-height: 1.625;
  }
  .newsletter__part--title {
    font-size: 20px;
    line-height: 1.3;
  }
  div.link,
  div.load {
    font-size: 15px;
    line-height: 1.6;
  }
  div.link a.open {
    background-position: 100% 6px;
  }
  div.link.site a.open {
    background-position: 100% 7px;
  }
  div.load a.load {
    background-size: auto 24px;
    background-position: 100% 0;
  }
  .area--six .seam div.link a.open {
    font-size: 16px;
    line-height: 1.5;
  }
}
.area--one .pure.wide .body {
  width: 104%;
  margin-left: -2%;
}
.area--one .pure.wide .part {
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
}
@media (max-width: 1197px) {
  .area--five .unit div.link {
    text-align: center;
  }
}
.patch {
  display: none;
  position: absolute;
  z-index: 1;
  height: auto;
}
@media (max-width: 1197px) {
  .patch {
    display: none !important;
  }
}
.patch--green {
  width: 460px;
}
.patch--blue {
  width: 482px;
}
.patch--lightblue {
  width: 536px;
}
.patch--yellow {
  width: 542px;
}
.patch--lila {
  width: 424px;
}
.patch--red {
  width: 440px;
}
.patches--2 .patch--green {
  display: block;
  left: -146px;
  bottom: 2042px;
}
.patches--2 .patch--lightblue {
  display: block;
  right: -166px;
  bottom: 760px;
}
.patches--3 .patch--yellow {
  display: block;
  left: -255px;
  top: 1364px;
}
.patches--3 .patch--blue {
  display: block;
  right: -198px;
  top: 3562px;
}
.patches--4 .patch--lila {
  display: block;
  right: -170px;
  top: 194px;
}
.patches--5 .patch--lila {
  display: block;
  right: -170px;
  bottom: 724px;
}
.patches--6 .patch--lila {
  display: block;
  right: -170px;
  top: 194px;
}
.patches--6 .patch--yellow {
  display: block;
  left: -255px;
  bottom: 600px;
}
.patches--7 .patch--red {
  display: block;
  right: -220px;
  top: 390px;
}
.patches--7 .patch--green {
  display: block;
  left: -146px;
  bottom: 854px;
}
.patches--8 .patch--lightblue {
  display: block;
  right: -156px;
  top: 1320px;
}
.patches--8 .patch--yellow {
  display: block;
  left: -255px;
  top: 2466px;
}
.ns-unitFilter {
  float: right;
  width: 100%;
  position: relative;
  z-index: 2;
}
.ns-unitFilter__wrapper {
  float: left;
  width: 100%;
  position: relative;
}
.ns-unitFilter__toggle {
  float: left;
  width: 100%;
  cursor: pointer;
  border: 1px solid #737B7D;
  background-color: #fff;
  font-size: 25px;
  line-height: 1.2;
  padding: 19px 78px 19px 32px;
  position: relative;
  box-sizing: border-box;
}
.ns-unitFilter__toggle:after {
  content: '';
  position: absolute;
  right: 32px;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  border-style: solid;
  border-color: transparent;
  border-top-color: #A4AAAC;
  border-width: 10px 8px 0 8px;
}
.ns-unitFilter__toggle:hover {
  background-color: #f7f7f7;
}
.ns-unitFilter__toggle.is-active:after {
  transform: translateY(-50%) rotate(-180deg);
}
@media (max-width: 1197px) {
  .ns-unitFilter__toggle {
    font-size: 16px;
    line-height: 1.5;
    padding: 13px 56px 13px 20px;
  }
  .ns-unitFilter__toggle:after {
    right: 20px;
  }
}
.ns-unitFilter__content {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: -1px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #737B7D;
  background-color: #fff;
  display: none;
}
.ns-unitFilter.is-open .ns-unitFilter__content {
  display: block;
}
.ns-unitFilter__item {
  float: left;
  width: 100%;
  font-size: 25px;
  line-height: 1.2;
  padding: 22px 32px;
  cursor: pointer;
  text-decoration: none;
  border-top: 1px solid #737B7D;
  box-sizing: border-box;
  color: #212526 !important;
}
.ns-unitFilter__item:hover {
  background-color: #f7f7f7;
}
.ns-unitFilter__item.is-active {
  font-weight: 600;
}
.ns-unitFilter__item:first-child {
  border: none;
}
@media (max-width: 1197px) {
  .ns-unitFilter__item {
    font-size: 16px;
    line-height: 1.5;
    padding: 13px 20px;
  }
}
.ns-unitFilter__unit {
  display: none;
  opacity: 0;
  transform: scale(0.98);
  transition: all 0.4s;
}
.ns-unitFilter__unit.ns-unitFilter__unit--activated {
  display: block;
}
.ns-unitFilter__unit.ns-unitFilter__unit--active {
  opacity: 1;
  transform: scale(1);
}
/*# sourceMappingURL=./screen-small.css.map */